// @ts-ignore
import { FilterDescriptor, FilterValuesType } from '../contexts/Filtering';

function convertCriteriaToFilterDescriptor(criteria: Record<string, any>, filters: Record<string, FilterDescriptor>):
{
  newActiveFilterIds: string[],
  newFilters: Record<string, FilterDescriptor>
} {
  // for tests, get the output of this and compare the correct output!!!!!
  const newActiveFilterIds: string[] = [];
  // TODO HELEN console.log('convertCriteriaToFilterDescriptor criteria', criteria);
  const allKeys = Object.keys(filters);
  // TODO HELEN console.log('allKeys', allKeys);

  let newFilters = { ...filters }; // Initialize with the current state of filters
  for (const filterId in criteria) {
    if (criteria[filterId] === null) {
      continue;
    }

    // if the filter does not have a value, skip it
    if (criteria[filterId] === null || criteria[filterId] === undefined || criteria[filterId] === '' || criteria[filterId]?.length === 0) {
      // TODO HELEN console.log('***The following id does not have a value in criteria:', filterId, criteria[filterId]);
      continue;
    }

    if (filterId === 'version') {
      // version is not a filter, it is a property of the search criteria object
      continue;
    }

    if (filterId.startsWith('max_')) {
      const key = filterId.substring(4);
      if (!allKeys.includes(key)) {
        // TODO HELEN console.log('***The following is not in available filters:', key);
        continue;
      }
      newActiveFilterIds.push(key);
      newFilters[key] = {
        ...newFilters[key],
        values: {
          ...newFilters[key]?.values,
          max: criteria[filterId]
        } as FilterValuesType,
      };
      continue;
    }

    if (filterId.startsWith('min_')) {
      const key = filterId.substring(4);
      if (!allKeys.includes(key)) {
        // TODO HELEN console.log('***The following is not in available filters:', key);
        continue;
      }
      newActiveFilterIds.push(key);

      newFilters[key] = {
        ...filters[key],
        values: {
          ...newFilters[key].values,
          min: criteria[filterId]
        } as FilterValuesType,
      };
      continue;
    }
    if (!allKeys.includes(filterId)) {
      // TODO HELEN console.log('***The following is not in available filters:', filterId);
      continue;
    }

    switch (filterId) {
    case 'title': {
      // do we need to handle this one?
      break;
    }
    case 'status':
    case 'pool':
    case 'data_source':
    case 'how_sold':
    case 'property_class':
    case 'recent_rehab':
    case 'sale_or_rent':
    case 'proposed_terms':
    case 'wanted':
    {
      let newValues = [];
      for (const s of criteria[filterId]) {
        const options = filters[filterId]?.options?.state;
        for (const option of options) {
          if (option.code == s) {
            newValues.push(option);
          }
        }
      }
      if (newValues.length === 0) {
        continue;
      }
      // these are a little interesting because they are an object with other props
      newActiveFilterIds.push(filterId);
      newFilters[filterId] = {
        ...newFilters[filterId],
        values: {
          state: newValues
        } as FilterValuesType,
      };
      break;
    }
    case 'property_id':
    case 'clerk_number':
    case 'county_code':
    case 'street':
    case 'subdivision':
    case 'tax_id':
    case 'zillow_url':
    case 'last_mls_id':
    case 'full_address':
    case 'custom_auction_number':
    case 'zipcode':
    case 'city': {
      newActiveFilterIds.push(filterId);
      newFilters[filterId] = {
        ...newFilters[filterId],
        values: {
          query: criteria[filterId]
        } as FilterValuesType,
      };
      break;
    }
    case 'favorite': {
      newActiveFilterIds.push(filterId);
      newFilters[filterId] = {
        ...newFilters[filterId],
        values: {
          state: criteria[filterId] === 'true' || criteria[filterId] === true ? true : false
        } as FilterValuesType,
      };
      break;
    }
    default:
      console.error('***not handling:', filterId);
      break;
    }
  }

  // Remove duplicates from newActiveFilterIds
  const ret = { newActiveFilterIds: Array.from(new Set(newActiveFilterIds)), newFilters };
  return ret;
};

export { convertCriteriaToFilterDescriptor };
