import React, { useEffect, type FC, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DecisionMakingContextProvider } from '../../contexts/DecisionMaking';

import { useGetListSorts } from '../../hooks/api/ListSort/useGetListSorts';

import DecisionMakingHeader from './DecisionMakingHeader';
import NoListSortsModal from './NoListSortsModal';
import { Provider, useDispatch } from 'react-redux';
import { AppDispatch, store } from '../../redux/store';
import { enableMapSet } from 'immer';
enableMapSet();
import DecisionMakingContainer from './DecisionMakingContainer';
import { setMapKey } from '../../redux/state/mapSlice';
import { FilteringProvider } from '../../contexts/Filtering';
import { AppSettingsProvider } from '../../contexts/AppSettings';
import DecisionMakingPropertySidebar from './DecisionMakingPropertySidebar';
import { LoadingSpinner } from '../icons/OurIcons';
import { fetchColumns } from '../../redux/state/columnsSlice';
import useSessionStorage from '../../hooks/useSessionStorage';
import { fetchFavoritesThunk } from '../../redux/state/favoriteSlice';

export type DecisionMakingViewerProps = {
  mapKey: string;
  incomingListSortId: string;
}

const queryClient = new QueryClient();

const DecisionMakingViewer: FC<DecisionMakingViewerProps> = ({ mapKey, incomingListSortId }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <FilteringProvider>
          <AppSettingsProvider>
            <DecisionMakingContextProvider>
              <DecisionMakingViewerChild mapKey={mapKey} incomingListSortId={incomingListSortId} />
            </DecisionMakingContextProvider>
          </AppSettingsProvider>
        </FilteringProvider>
      </QueryClientProvider>
    </Provider>
  );
};

const DecisionMakingViewerChild: FC<DecisionMakingViewerProps> = ({ mapKey, incomingListSortId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [ activeListSort, setActiveListSort ] = useState(null);
  const { storedValue: activeListSortId, setValue: setActiveListSortId } = useSessionStorage('activeListSort', incomingListSortId);
  const { storedValue: activePropertyId, setValue: setActivePropertyId } = useSessionStorage('decisionMakingLastActiveProperty', null);
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(false);
  const { data: listSorts, isLoading: listSortsLoading } = useGetListSorts();

  useEffect(() => {
    dispatch(setMapKey(mapKey));
    dispatch(fetchColumns());
    dispatch(fetchFavoritesThunk());
    // Read the propertyId from the URL and remove it
    const url = new URL(window.location.href);
    const propertyId = url.searchParams.get('propertyId');
    if (propertyId) {
      setActivePropertyId(propertyId);
      url.searchParams.delete('propertyId');
      window.history.replaceState({}, '', url.toString());
    }
  }, []);

  useEffect(() => {
    if (activeListSort && activeListSort?.id) {
      // set the active list sort id in session storage
      setActiveListSortId(activeListSort.id);
    }
  }, [ activeListSort ]);

  useEffect(() => {
    if (incomingListSortId) {
      const incomingListSort = listSorts?.find((listSort) => listSort.id === incomingListSortId);
      if (incomingListSort) {
        setActiveListSort(incomingListSort);
        if (activeListSortId !== incomingListSort.id) {
          setActiveListSortId(incomingListSort.id);
        }
      }
    }
    else {
      if (activeListSortId) {
        const activeListSort = listSorts?.find((listSort) => listSort.id === activeListSortId);
        if (activeListSort) {
          setActiveListSort(activeListSort);
        }
      }
    }
  }, [ listSorts, incomingListSortId ]);

  const navigateToSelectedListSort = (listSortId: string) => {
    const activeListSort = listSorts?.find((listSort) => listSort.id === listSortId);
    if (!activeListSort) {
      return;
    }
    setActiveListSort(activeListSort);
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    url.searchParams.set('id', listSortId);
    history.pushState({}, '', url.toString());
  };

  if (listSortsLoading) {
    return <LoadingSpinner />;
  }

  if (!listSorts?.length) {
    return (
      <div className='w-screen content-center px-10'>
        <div className='mx-auto mt-36 px-96 w-5/6'>
          <NoListSortsModal />
        </div>
      </div>
    );
  }

  const DecisionMakingInvalidStates = () => {
    if (!activeListSort) {
      return (
        <div id='DecisionMakingViewer-no-active-list-sort-container' className='flex flex-row justify-center p-5'>
          <div className='text-center'>
            <p className='text-xl'>Please select a list sort.</p>
          </div>
        </div>
      );
    }

    if (!activeListSort?.total_properties) {
      return (
        <div className='w-screen content-center px-10'>
          <div className='mx-auto mt-36 px-96 w-5/6'>
            <div className='text-center'>
              <p className='text-2xl'>No properties found for this list sort</p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='w-screen max-h-full top-0 pb-10 text-xs justify-center'>
      <DecisionMakingHeader
        title='Decision Making'
        activeListSort={activeListSort}
        listSorts={listSorts}
        navigateToSelectedListSort={navigateToSelectedListSort}
      />
      {!activeListSort?.total_properties
        ? <DecisionMakingInvalidStates />
        : (
          <div id='DecisionMakingViewer-property-sidebar-container' className='flex flex-row'>
            <div className={`${isSidebarOpen ? 'w-1/6 min-w-[250px]' : ''} max-w-[250px]`}>
              <DecisionMakingPropertySidebar
                listSortId={activeListSort?.id}
                activePropertyId={activePropertyId}
                setActivePropertyId={setActivePropertyId}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
              />
            </div>
            <div id='DecisionMakingViewer-decision-making-container' className='w-full'>
              <DecisionMakingContainer
                propertyId={activePropertyId}
                activeListSort={activeListSort}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default DecisionMakingViewer;
