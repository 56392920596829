import { LayerProps } from 'react-map-gl';

export const PROPERTIES = 'properties';
export const LASSO = 'lasso';

export const clusterLayer: LayerProps = {
  id: 'clusters',
  source: PROPERTIES,
  type: 'circle',
  filter: [ 'has', 'point_count' ],
  paint: {
    'circle-color': [ 'step', [ 'get', 'point_count' ], '#51bbd6', 100, '#f1f075', 750, '#f28cb1' ],
    'circle-radius': [ 'step', [ 'get', 'point_count' ], 20, 100, 30, 750, 40 ]
  }
};

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  source: PROPERTIES,
  type: 'symbol',
  filter: [ 'has', 'point_count' ],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': [ 'DIN Offc Pro Medium', 'Arial Unicode MS Bold' ],
    'text-size': 12
  }
};

export const lassoLine: LayerProps = {
  id: 'lasso-line',
  source: LASSO,
  type: 'line',
  paint: {
    'line-color': '#4F46E5',
    'line-width': 3
  }
};

export const lassoFill: LayerProps = {
  id: 'lasso-fill',
  source: LASSO,
  type: 'fill',
  paint: {
    'fill-color': '#4F46E5',
    'fill-opacity': 0.2
  }
};
