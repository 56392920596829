import { useQuery } from '@tanstack/react-query';
import { getPropertyMapMarkersForListSort } from '../../../api/Property/getPropertyMapMarkersForListSort';
import { getPropertyMapMarkers } from '../../../api/Property/getPropertyMapMarkers';

export default function useGetPropertyMapMarkers(
  listSortId,
  ransackObj,
  numResults
) {
  const options = {
    enabled: ransackObj?.state !== 'unset'
  };

  const queryConfig = listSortId ? {
    queryKey: [ 'getPropertyMapMarkersForListSort', listSortId, ransackObj, numResults ],
    queryFn: () => getPropertyMapMarkersForListSort({
      listSortId,
      ransackObj,
      numResults
    })
  } : {
    queryKey: [ 'getPropertyMapMarkers', ransackObj, numResults ],
    queryFn: () => getPropertyMapMarkers({
      ransackObj,
      numResults
    })
  };

  const markersQuery = useQuery({
    ...options,
    ...queryConfig
  });

  const data = markersQuery?.data?.data;

  return {
    propertiesData: data?.properties_json,
    loadingProperties: markersQuery.isLoading,
    pendingProperties: markersQuery.isPending,
    errorProperties: markersQuery.error,
    propertyCounts: {
      total_property_records: data?.total_property_records,
      total_property_records_filtered: data?.total_property_records_filtered,
      total_wanted_property_joins: data?.total_wanted_property_joins,
      total_unwanted_property_joins: data?.total_unwanted_property_joins,
      total_sorted_property: data?.total_sorted_property,
      total_unsorted_property: data?.total_unsorted_property,
    },
  };
}
