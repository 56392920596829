import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AddressType, PropertyDataProps } from '../../utility/types';
import React from 'react';
import Map, { MapRef, Marker, PointLike, Popup } from 'react-map-gl';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import PropertyQuickViewModal from './modals/PropertyQuickViewModal';
import { polygon } from '@turf/helpers';
import { useFilteringContext } from '../../contexts/Filtering';
import { LoadingSpinner } from '../icons/OurIcons';
import { createMarker, getMapboxBoundingBox, getMapboxPopupOrientationAndOffset, moveMapMarkerToLeftEdge, POPUP_WIDTH, WIDE_MAP, Z_INDEX_MAP_POPUP } from '../../utility/mapUtilities';
import useGetPropertyMapMarkers from '../../hooks/api/Property/useGetPropertyMapMarkers';
import { SATELLITE_VIEW_STYLE, STREET_VIEW_STYLE } from '../../utility/constants';

export type NearbyListingsMapProps = {
  property: PropertyDataProps
};

const NearbyListingMap: FC<NearbyListingsMapProps> = ({ property }) => {
  const mapKey = useSelector((state: RootState) => state.map.key);
  const favorites = useSelector<RootState>(state => state.favorite.favorites);
  const markersRef = useRef({});
  const mapRef = useCallback((node: MapRef) => {
    if (node !== null) {
      setMapNode(node);
      setMoveMapToFitPopup(getMapboxBoundingBox(node).width < WIDE_MAP);
    }
  }, []);

  let { ransackObj } = useFilteringContext();

  const [ mapNode, setMapNode ] = useState(null);
  const [ popupHeight, setPopupHeight ] = useState(0);
  const [ popupLoading, setPopupLoading ] = useState(false);
  const [ previousCenter, setPreviousCenter ] = useState(null);
  const [ localRansackObj, setLocalRansackObj ] = useState(null);
  const [ popupInfo, setPopupInfo ] = useState<AddressType>(null);
  const [ isSatelliteView, setIsSatelliteView ] = useState(false);
  const [ popupOrientation, setPopupOrientation ] = useState(null);
  const [ moveMapToFitPopup, setMoveMapToFitPopup ] = useState(false);
  const [ popupOffset, setPopupOffset ] = useState<PointLike>([ 0, 0 ]);

  useEffect(() => {
    if (popupHeight && popupInfo) {
      setPopupOrientationAndOffset(popupInfo.id);
    }
  }, [ popupHeight ]);

  const startingCenter = {
    lat: +property?.address?.latitude,
    lng: +property?.address?.longitude
  };

  const { propertiesData, loadingProperties, pendingProperties } = useGetPropertyMapMarkers(null, localRansackObj, 500);

  const setPopupOrientationAndOffset = (id: string) => {
    let orientation, offset;
    const marker = markersRef.current[id];

    if (moveMapToFitPopup) {
      orientation = 'left';
      offset = [ 18, 0 ];
    }
    else {
      [ orientation, offset ] = getMapboxPopupOrientationAndOffset(marker, mapNode, popupHeight);
    }

    setPopupOrientation(orientation);
    setPopupOffset(offset);
  };

  const calculateNearbyListings = () => {
    if (!mapNode || !property) {
      return [];
    }

    const bounds = mapNode.getBounds();
    const boundaryPoints = [
      bounds.getNorthEast(),
      bounds.getNorthWest(),
      bounds.getSouthWest(),
      bounds.getSouthEast(),
      bounds.getNorthEast()
    ].map(point => [ point.lng, point.lat ]);

    const areaToBeSearched = [ polygon([ boundaryPoints ]) ];

    if (areaToBeSearched) {
      setLocalRansackObj({
        ...ransackObj,
        gisFilter: { areaToBeSearched }
      });
    }
  };

  const toggleSatelliteView = () => {
    const newStyle = isSatelliteView ? STREET_VIEW_STYLE : SATELLITE_VIEW_STYLE;
    mapNode.getMap().setStyle(newStyle);
    setIsSatelliteView(!isSatelliteView);
  };

  const clearSelection = () => {
    setPopupInfo(null);

    if (moveMapToFitPopup) {
      mapNode.setCenter(previousCenter);
    }
  };

  const getMarkerHtml = (markerProperty) => {
    if (!markerProperty.address) return;

    const address = markerProperty.address;
    const isSelected = address.id === popupInfo?.id;
    const isMainProperty = address?.id === property?.address?.id;
    const isRental = markerProperty.sale_or_rent?.toUpperCase() === 'RENT';

    return (
      <Marker
        longitude={address.longitude}
        latitude={address.latitude}
        key={address.id}
        ref={el => markersRef.current[address.id] = el}
        style={{ zIndex: isSelected ? Z_INDEX_MAP_POPUP : 0 }}
        onClick={e => {
          e.originalEvent.stopPropagation();
          setPopupInfo(address);

          if (moveMapToFitPopup) {
            moveMapMarkerToLeftEdge(mapNode, address, setPreviousCenter);
          }
          else {
            setPopupHeight(0);
          }
        }}
      >
        {createMarker({
          addressId: address.id,
          popupInfoId: popupInfo?.id,
          listPrice: markerProperty?.list_price,
          isFavorite: !!favorites[markerProperty.id],
          isMainProperty,
          isRental,
          popupLoading
        })}
      </Marker>
    );
  };

  const markers = useMemo(() => {
    if (property && propertiesData) {
      const properties = propertiesData;
      const propertyIndex = properties.findIndex(prop => prop.id === property.id);
      if (propertyIndex !== -1) {
        properties.splice(propertyIndex, 1);
      }
      properties.push(property);

      const markers = properties?.map((prop) => {
        const address = prop?.address;
        if ((prop?.list_price || prop.id === property.id) && address?.latitude && address?.longitude) {
          return getMarkerHtml(prop);
        }
      });
      return markers;
    }
  }, [ property, propertiesData, popupInfo, popupLoading, favorites ]);

  return (
    <div className='h-full relative'>
      {!property || (pendingProperties || loadingProperties) &&
        <div className="absolute inset-0 flex items-center justify-center bg-slate-500/20 z-10">
          <LoadingSpinner></LoadingSpinner>
        </div>
      }
      <div className='flex justify-end p-2 absolute w-full pointer-events-none z-10'>
        <button className='py-0.5 px-1 pointer-events-auto rounded-sm bg-neutral-50 text-indigo-600 border-indigo-600 border-2 hover:text-indigo-800 hover:border-indigo-800'
          onClick={toggleSatelliteView}>
          {isSatelliteView ? 'Show Standard View' : 'Show Satellite View'}
        </button>
      </div>
      {propertiesData?.length === 1 &&
        <div className='absolute top-[55%] left-1/2 -translate-x-1/2 z-10'>
          <h2 className={`justify-self-start ${isSatelliteView ? 'text-neutral-50 drop-shadow-[0px_0px_2px_black]' : 'text-neutral-950 drop-shadow-[0px_0px_2px_white]'}`}>
            No Nearby Listings
          </h2>
        </div>
      }
      <Map
        ref={mapRef}
        mapboxAccessToken={mapKey}
        initialViewState={{
          longitude: startingCenter.lng,
          latitude: startingCenter.lat,
          zoom: 14
        }}
        mapStyle={STREET_VIEW_STYLE}
        onLoad={calculateNearbyListings}
      >
        {markers}
        {popupInfo && (
          <Popup
            longitude={+popupInfo.longitude}
            latitude={+popupInfo.latitude}
            maxWidth={`${POPUP_WIDTH}px`}
            anchor={popupOrientation}
            offset={popupOffset}
            closeButton={false}
            closeOnMove={true}
            style={{
              zIndex: Z_INDEX_MAP_POPUP,
              visibility: popupHeight > 0 ? 'visible' : 'hidden'
            }}
            onClose={clearSelection}
          >
            <PropertyQuickViewModal
              propertyId={popupInfo.property_id}
              setPopupLoading={setPopupLoading}
              setHeight={setPopupHeight}
            />
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default NearbyListingMap;
