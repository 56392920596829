import React, { useEffect, useState, type FC } from 'react';

export type DualValueRangeComponentProps = {
  min: number;
  max: number;
  title: string;
  unit: string;
  id: string;
  onValuesChange: (min: number, max: number) => void;
}

const buttonClasses = 'bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600';
const inputClasses = 'px-3 py-2 border w-full rounded-lg focus:outline-none focus:border-blue-500';
const labelClasses = 'block text-gray-800 mb-1';
const labelInputContainerClasses = 'flex flex-row w-full mb-4';

const DualValueRangeComponent: FC<DualValueRangeComponentProps> = ({
  min,
  max,
  title,
  id,
  unit,
  onValuesChange,
}) => {
  const [ minLocal, setMin ] = useState(min);
  const [ maxLocal, setMax ] = useState(max);

  useEffect(() => {
    setMin(min);
    setMax(max);
  }, [ min, max ]);

  // Handler for input changes
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'min') {
      setMin(Number(value));
    } else if (name === 'max') {
      setMax(Number(value));
    }
  };

  // Handler for applying new values
  const handleApplyValues = () => {
    onValuesChange(minLocal, maxLocal);
  };

  const handleInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // Check if max is less than min and swap if necessary
    if (name === 'max' && Number(value) < min) {
      setMin(Number(value));
    } else if (name === 'min' && Number(value) > max) {
      setMax(Number(value));
    }
    if (name === 'min') {
      setMin(Number(value));
    } else if (name === 'max') {
      setMax(Number(value));
    }
  };

  return (
    <div className="bg-gray-100 p-2 rounded-lg shadow-md w-full">
      <div className="flex flex-col w-full">
        <div className={labelInputContainerClasses}>
          <label className={labelClasses}>Min {unit}</label>
          <input
            type="number"
            name="min"
            value={minLocal}
            onChange={handleInputChange}
            onBlur= {handleInputBlur}
            className={inputClasses}
          />
        </div>
        <div className={labelInputContainerClasses}>
          <label className={labelClasses}>Max {unit}</label>
          <input
            type="number"
            name="max"
            value={maxLocal}
            onChange={handleInputChange}
            onBlur= {handleInputBlur}
            className={inputClasses}
          />
        </div>
      </div>
      <button
        onClick={handleApplyValues}
        className={buttonClasses}
      >
        Apply Values
      </button>
      <p className="mt-4 text-gray-800">Current Min: {min}</p>
      <p className="text-gray-800">Current Max: {max}</p>
    </div>
  );
};

export default DualValueRangeComponent;
