import { api } from '../configs/axiosConfigs';

export type GetPropertyMapMarkersForListSortProps = {
  listSortId: string;
  ransackObj: any;
  numResults: number;
}

// Shared abort controller for cancelling in-flight requests
let abortController: AbortController | null = null;

export const getPropertyMapMarkersForListSort = async({
  listSortId,
  ransackObj,
  numResults
}: GetPropertyMapMarkersForListSortProps) => {
  // Cancel any pending requests
  if (abortController) {
    abortController.abort();
  }

  // Create new controller for this request
  abortController = new AbortController();

  try {
    // Build query params
    const params = new URLSearchParams();
    params.append('num_results', numResults.toString());

    // Make request
    const response = await api.post(
      `/list-sorts/${listSortId}/map_markers?${params.toString()}`,
      ransackObj,
      { signal: abortController.signal }
    );

    return response;
  } finally {
    // Clean up controller
    abortController = null;
  }
};
