import React, { useEffect, useState, type FC, useCallback, useMemo, useRef } from 'react';
import { GeoFeatureAddressFavorite, PropertiesMapProps } from '../../utility/types';
import Map, { Layer, Marker, Popup, Source } from 'react-map-gl';
import type { GeoJSONSource, MapRef, PointLike } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import PropertyQuickViewModal from '../common/modals/PropertyQuickViewModal';
import '../../../assets/stylesheets/mapbox.css';
import DrawControl, { drawRef } from './draw-control';
import * as turf from '@turf/turf';
import { createMarker, getMapboxPopupOrientationAndOffset, POPUP_WIDTH, Z_INDEX_MAP_POPUP, Z_INDEX_SELECTED_MARKER } from '../../utility/mapUtilities';
import { useFilteringContext } from '../../contexts/Filtering';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FreehandPolygonMode from './FreehandPolygon';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import Header from '../property-list-viewer/Header';
import { usePropertyViewerContext } from '../../contexts/PropertyViewer';
import * as layersUtil from './MapLayersUtil';
import { LoadingSpinner } from '../icons/OurIcons';
import useGetPropertyMapMarkers from '../../hooks/api/Property/useGetPropertyMapMarkers';
import { SATELLITE_VIEW_STYLE, STREET_VIEW_STYLE } from '../../utility/constants';

const PropertiesMap: FC<PropertiesMapProps> = ({ listSortId }) => {
  return (
    <div className='h-[80vh] w-full relative'>
      <div className='h-fit border border-gray-200 p-2'>
        <Header showMapFilterDisclaimer={false} />
      </div>
      <PropertiesMapContainer listSortId={listSortId} />
    </div>
  );
};

const PropertiesMapContainer: FC<PropertiesMapProps> = ({ listSortId }) => {
  const favorites = useSelector<RootState>(state => state.favorite.favorites);
  const mapKey = useSelector((state: RootState) => state.map.key);
  const mapButtonStyle = 'py-0.5 px-1 rounded-sm bg-neutral-50 text-indigo-600 border-indigo-600 border-2 hover:text-indigo-800 hover:border-indigo-800 pointer-events-auto';
  const markersRef = useRef({});

  const {
    setTotalPropertiesCount,
    setPropertyDecisionCounts,
    setCriteriaMatchedPropertiesCount
  } = usePropertyViewerContext();

  const mapRef = useCallback((node: MapRef) => {
    if (node !== null) {
      setMapNode(node);
    }
  }, []);

  let mapDrawRef = drawRef;

  const { ransackObj, filteringDispatch } = useFilteringContext();

  const { propertiesData,
    propertyCounts,
    loadingProperties,
    pendingProperties,
    errorProperties } = useGetPropertyMapMarkers(listSortId, ransackObj, 500);

  useEffect(() => {
    if (propertiesData) {
      setTotalPropertiesCount(propertyCounts.total_property_records);
      setPropertyDecisionCounts({
        wantedPropertiesCount: propertyCounts.total_wanted_property_joins,
        unwantedPropertiesCount: propertyCounts.total_unwanted_property_joins,
        decidedPropertiesCount: propertyCounts.total_sorted_property,
        undecidedPropertiesCount: propertyCounts.total_unsorted_property,
      });
      setCriteriaMatchedPropertiesCount(propertyCounts.total_property_records_filtered);
    }
  }, [ propertiesData ]);

  // Calculate map bounds from properties or lasso coordinates
  const getCoordinatesFromLasso = (lasso) => {
    return {
      latitudes: lasso?.map(l => l.geometry.coordinates[0].map(coord => coord[1]))?.flat() ?? [],
      longitudes: lasso?.map(l => l.geometry.coordinates[0].map(coord => coord[0]))?.flat() ?? []
    };
  };

  const getCoordinatesFromProperties = (properties) => {
    const validProperties = properties.filter(property => {
      const { latitude, longitude } = property?.address;
      return !!latitude && !!longitude && +latitude !== 0 && +longitude !== 0;
    });

    if (validProperties.length === 0) {
      return { latitudes: [], longitudes: [] };
    }

    return {
      latitudes: validProperties
        .map(p => p?.address?.latitude)
        .map(Number)
        .filter(Boolean),
      longitudes: validProperties
        .map(p => p?.address?.longitude)
        .map(Number)
        .filter(Boolean)
    };
  };

  const createBounds = (latitudes, longitudes) => {
    if (latitudes.length === 0 || longitudes.length === 0) {
      return new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(0, 0),
        new mapboxgl.LngLat(0, 0)
      );
    }

    return new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(Math.min(...longitudes), Math.min(...latitudes)),
      new mapboxgl.LngLat(Math.max(...longitudes), Math.max(...latitudes))
    );
  };

  const getCoordinates = () => {
    if (!propertiesData) {
      return { latitudes: [], longitudes: [] };
    }

    const gisFilter = ransackObj?.gisFilter;

    if (gisFilter?.lasso) {
      return getCoordinatesFromLasso(gisFilter.lasso);
    }

    if (gisFilter?.areaToBeSearched) {
      return getCoordinatesFromLasso(gisFilter.areaToBeSearched);
    }

    return getCoordinatesFromProperties(propertiesData);
  };

  const { latitudes, longitudes } = getCoordinates();

  const bounds = createBounds(latitudes, longitudes);

  const [ popupInfo, setPopupInfo ] = useState(null);
  const [ popupOrientation, setPopupOrientation ] = useState(null);
  const [ popupOffset, setPopupOffset ] = useState<PointLike>([ 0, 0 ]);
  const [ lassoSelections, setLassoSelections ] = useState({});
  const [ mapNode, setMapNode ] = useState(null);
  const [ isSatelliteView, setIsSatelliteView ] = useState(false);
  const [ isLassoDrawMode, setIsLassoDrawMode ] = useState(false);
  const [ propertiesGeoJson, setPropertiesGeoJson ] = useState(null);
  const [ lassoGeoJson, setLassoGeoJson ] = useState(null);
  const [ isUserDrawnLasso, setIsUserDrawnLasso ] = useState(false);
  const [ unclusteredFeatures, setUnclusteredFeatures ] = useState([]);
  const [ popupLoading, setPopupLoading ] = useState(false);
  const [ popupHeight, setPopupHeight ] = useState(0);

  useEffect(() => {
    if (!isLassoDrawMode) {
      handleBoundsChange();
    }
  }, [ propertiesData ]);

  useEffect(() => {
    if (popupHeight && popupInfo) {
      setPopupOrientationAndOffset(popupInfo.id);
    }
  }, [ popupHeight ]);

  // Create GeoJson for lasso tool layers
  useEffect(() => {
    const lasso = ransackObj?.gisFilter?.lasso ?? [];

    const lassoGeoJson = {
      type: 'FeatureCollection',
      features: lasso
    };

    setLassoGeoJson(lassoGeoJson);
    setIsUserDrawnLasso(lasso.length > 0);

  }, [ ransackObj ]);

  // Process properties into GeoJSON format
  useEffect(() => {
    if (!propertiesData) return;

    const features = propertiesData.map(property => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [ +property.address.longitude, +property.address.latitude ]
        },
        properties: {
          address: property.address,
          favorite: !!favorites[property.id],
          listPrice: property.list_price,
          saleOrRent: property.sale_or_rent
        }
      } as GeoFeatureAddressFavorite;
    });

    const json = {
      type: 'FeatureCollection',
      features: features
    };

    if (!isLassoDrawMode) {
      setPropertiesGeoJson(json);
    }
  }, [ propertiesData, favorites, isLassoDrawMode ]);

  // Handle setting lasso draw modes and saving drawn lassos to ransack
  useEffect(() => {
    if (!!mapNode && !!mapDrawRef) {
      // When you're done drawing
      if (!isLassoDrawMode) {
        mapDrawRef.changeMode('simple_select');
        calculatePropertiesInLasso();
      }
      else {
        mapDrawRef.changeMode('draw_polygon');
      }
    }
  }, [ isLassoDrawMode ]);

  const shouldShowLasso = () => Object.keys(lassoSelections).length !== 0 || isUserDrawnLasso;

  const toggleSatelliteView = () => {
    if (mapNode) {
      const newStyle = isSatelliteView ? STREET_VIEW_STYLE : SATELLITE_VIEW_STYLE;
      mapNode.getMap().setStyle(newStyle);
      setIsSatelliteView(!isSatelliteView);
    }
  };

  const toggleLassoDrawMode = () => {
    setIsLassoDrawMode(!isLassoDrawMode);
  };

  const clearLassoSelections = () => {
    if (mapDrawRef) {

      const featureCollection = mapDrawRef.getAll();
      if (featureCollection && featureCollection.features.length > 0) {
        try {
          mapDrawRef.deleteAll();
        } catch (e) {
          console.error('Error deleting map features');
        }
      }

      setLassoSelections({});

      // Prevents you from needing to click the map before being able to draw another lasso
      mapDrawRef.changeMode(mapDrawRef.getMode());

      if (ransackObj?.gisFilter?.lasso) {
        calculatePropertiesInView();
      }
    }
  };

  const getMapBoundsAsPolygonCoordinates = () => {
    const bounds = mapNode.getBounds();

    const ne = bounds.getNorthEast();
    const nePoint = [ ne.lng, ne.lat ];
    const nw = bounds.getNorthWest();
    const nwPoint = [ nw.lng, nw.lat ];
    const se = bounds.getSouthEast();
    const sePoint = [ se.lng, se.lat ];
    const sw = bounds.getSouthWest();
    const swPoint = [ sw.lng, sw.lat ];

    const coords = [ nePoint, nwPoint, swPoint, sePoint, nePoint ];
    return coords;
  };

  const calculatePropertiesInLasso = () => {

    if (Object.keys(lassoSelections).length > 0) {
      let lassoPolygons: turf.Feature<turf.Polygon>[] = [];
      let clippedLassoPolygons: turf.Feature<turf.Polygon>[] = [];
      const mapBounds = getMapBoundsAsPolygonCoordinates();
      const mapBoundsPolygon = turf.polygon([ mapBounds ]);

      for (const selection in lassoSelections) {
        lassoPolygons.push(turf.polygon(lassoSelections[selection].geometry.coordinates, { isUserDrawn: true }));
        const intersect = turf.intersect(lassoSelections[selection], mapBoundsPolygon) as turf.Feature<turf.Polygon>;

        if (intersect) {
          intersect.properties = {
            ...intersect.properties,
            isUserDrawn: true
          };
          clippedLassoPolygons.push(intersect);
        }
      }

      if (clippedLassoPolygons.length === 0) clippedLassoPolygons = lassoPolygons;

      const featureCollection = mapDrawRef.getAll();
      if (featureCollection && featureCollection.features.length > 0) {
        try {
          mapDrawRef.deleteAll();
        } catch (e) {
          console.error('Error deleting map features');
        }
      }

      setIsUserDrawnLasso(lassoPolygons.length > 0);
      filteringDispatch({
        type: 'SET_GIS_FILTER',
        gisFilter: {
          lasso: lassoPolygons,
          areaToBeSearched: clippedLassoPolygons
        }
      });
    }
  };

  const calculatePropertiesInView = () => {
    if (!mapNode) {
      return [];
    }

    const bounds = getMapBoundsAsPolygonCoordinates();
    const boundsPolygon = [ turf.polygon([ bounds ], { isUserDrawn: false }) ];

    filteringDispatch({
      type: 'SET_GIS_FILTER',
      gisFilter: {
        lasso: null,
        areaToBeSearched: boundsPolygon
      }
    });
  };

  const handleBoundsChange = () => {
    if (!mapNode) {
      return;
    }

    if (!isLassoDrawMode) {
      if (!isUserDrawnLasso) {
        calculatePropertiesInView();
      }
      else {
        calculatePropertiesInLasso();
      }
    }
  };

  const setPopupOrientationAndOffset = (id: string) => {
    let orientation, offset;
    const marker = markersRef.current[id];

    [ orientation, offset ] = getMapboxPopupOrientationAndOffset(marker, mapNode, popupHeight);

    setPopupOrientation(orientation);
    setPopupOffset(offset);
  };

  const handleMapClick = (e) => {

    e.originalEvent.stopPropagation();

    const features = e.features || [];
    if (features.length > 0) {
      const feature = features[0];

      // Cluster markers have a cluster_id, individual property markers do not
      // Handle cluster markers
      if (feature.properties.cluster_id) {
        const mapboxSource = mapNode.getMap().getSource(layersUtil.PROPERTIES) as GeoJSONSource;

        mapboxSource.getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
          if (err) {
            return;
          }

          mapNode.getMap().easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 500
          });
        });
      }
    }
  };

  const onUpdate = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const createPropertyMarker = (props) => {
    const address = JSON.parse(props.address);
    const isRental = props.saleOrRent?.toUpperCase() === 'RENT';
    const isSelected = address.id === popupInfo?.id;

    const markerIcon = (
      <Marker
        latitude={address.latitude}
        longitude={address.longitude}
        key={address.id}
        style={{ zIndex: isSelected ? Z_INDEX_SELECTED_MARKER : 0 }}
        ref={el => markersRef.current[address.id] = el}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setPopupInfo(address);
          setPopupHeight(0);
        }}
      >
        {createMarker({
          addressId: address.id,
          popupInfoId: popupInfo?.id,
          listPrice: props.listPrice,
          isFavorite: props.favorite,
          isMainProperty: false,
          isRental,
          popupLoading
        })}
      </Marker>
    );

    return markerIcon;
  };

  const extractUnclusteredFeatures = (e) => {
    if (e.sourceId === layersUtil.PROPERTIES) {
      const features = mapNode.querySourceFeatures(layersUtil.PROPERTIES);
      setUnclusteredFeatures(features.filter(feature => !feature.properties.cluster));
    }
  };

  const unclusteredMarkers = useMemo(() => {
    if (mapNode) {
      const markersOnMap = {};

      for (const feature of unclusteredFeatures) {
        const props = feature.properties;
        const address = JSON.parse(props.address);
        const id = address.id;

        // If there are multiple features for one address
        // Only make a marker for the first feature
        if (!markersOnMap[id]) {
          const marker = createPropertyMarker(props);
          markersOnMap[id] = marker;
        }
      }

      return Object.values(markersOnMap);
    }
  }, [ mapNode, unclusteredFeatures, popupLoading, popupInfo, favorites ]);

  const mapLoader = () => {
    return (
      <div className='absolute w-full h-full z-10 bg-slate-500/20'>
        <div className='absolute w-[10%] h-[10%] top-[45%] right-[45%]'>
          <LoadingSpinner></LoadingSpinner>
        </div>
      </div>
    );
  };

  if (!propertiesGeoJson && (pendingProperties || loadingProperties)) {
    return (
      <div className='h-[65vh] w-full relative'>
        {mapLoader()}
      </div>
    );
  }

  if (errorProperties) {
    return <div id='PropertiesViewApp-error'>Error: {errorProperties.message}</div>;
  }

  return (
    <div className='h-[65vh] w-full relative'>
      <div className={`grid grid-cols-2 absolute w-full pointer-events-none ${isLassoDrawMode ? 'z-[2] bg-slate-500/70' : ''}`}>
        {isLassoDrawMode && (
          < p className='m-2 col-start-1 self-center text-indigo-600 drop-shadow-[0px_0px_2px_white]'>
            {shouldShowLasso() ? 'Confirm Selection To Search' : 'Draw Your Selection'}
          </p>
        )}
        <div className="col-start-2 justify-self-end self-center flex m-2 gap-2 z-[2]">
          <button className={mapButtonStyle} onClick={toggleLassoDrawMode}>
            {isLassoDrawMode
              ? shouldShowLasso()
                ? 'Confirm Lasso'
                : 'Cancel Lasso'
              : 'Lasso Select'
            }
          </button>
          {shouldShowLasso() && (
            <button className={mapButtonStyle} onClick={clearLassoSelections}>
              Clear selection
            </button>
          )}
          <button className={mapButtonStyle} onClick={toggleSatelliteView}>
            {isSatelliteView ? 'Show Standard View' : 'Show Satellite View'}
          </button>
        </div>
      </div>
      {loadingProperties && (
        <div className='absolute w-8 h-8 top-2 left-2 z-10'>
          <LoadingSpinner />
        </div>
      )}
      <Map
        ref={mapRef}
        mapboxAccessToken={mapKey}
        initialViewState={{
          bounds: bounds,
          fitBoundsOptions: { padding: 20 }
        }}
        mapStyle={STREET_VIEW_STYLE}
        onZoomEnd={handleBoundsChange}
        onDragEnd={handleBoundsChange}
        onRotateEnd={handleBoundsChange}
        onClick={handleMapClick}
        onSourceData={extractUnclusteredFeatures}
        onMouseEnter={() => mapNode.getMap().getCanvas().style.cursor = 'pointer'}
        onMouseLeave={() => mapNode.getMap().getCanvas().style.cursor = ''}
        interactiveLayerIds={[ layersUtil.clusterLayer.id ]}
      >
        <Source
          id={layersUtil.LASSO}
          type='geojson'
          data={lassoGeoJson}
        >
          <Layer {...layersUtil.lassoFill} />
          <Layer {...layersUtil.lassoLine} />
        </Source>
        <Source
          id={layersUtil.PROPERTIES}
          type='geojson'
          data={propertiesGeoJson}
          cluster={true}
          clusterMaxZoom={12}
        >
          <Layer {...layersUtil.clusterLayer} />
          <Layer {...layersUtil.clusterCountLayer} />
        </Source>
        {unclusteredMarkers}
        {popupInfo && (
          <Popup
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            maxWidth={`${POPUP_WIDTH}px`}
            anchor={popupOrientation}
            offset={popupOffset}
            closeButton={false}
            closeOnMove={true}
            onClose={() => setPopupInfo(null)}
            style={{
              zIndex: Z_INDEX_MAP_POPUP,
              visibility: popupHeight > 0 ? 'visible' : 'hidden'
            }}
          >
            <PropertyQuickViewModal
              propertyId={popupInfo.property_id}
              setPopupLoading={setPopupLoading}
              setHeight={setPopupHeight}
            />
          </Popup>
        )}
        <DrawControl
          displayControlsDefault={false}
          modes={Object.assign(MapboxDraw.modes, {
            draw_polygon: FreehandPolygonMode
          })}
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      </Map>
    </div>
  );
};
export default PropertiesMap;
