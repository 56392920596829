import React, { useEffect, useState, type FC } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { usePropertyViewerContext } from '../../contexts/PropertyViewer';

import useGetSavedSearchCriteria from '../../hooks/api/SearchCriteria/useGetSavedSearchCriteria';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import SizeSettings from './SizeSettings';
import { PaginationPerPage, ShouldShowGridLines, ShouldShowStripedRows } from './TableSettingsMiniComponents';

import SaveSearchCriteria from './SaveSearchCriteria';
import { useFilteringContext } from '../../contexts/Filtering';

import RowSelectionToggle from './Components/selectors/RowSelectionToggle';
import ColumnsToggle from './Components/selectors/ColumnsToggle';
import FilterDisplay from './Components/filters/FilterDisplay';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import SavedSearchCard from './Components/filters/SavedSearchCard';

/**
 * Lets make it so when the search criteria is updated, the request is made to see how many properties satisfy the query
 * but when the "apply" button is clicked, it actually fetches those properties?
 */
const tabs = [
  { name: 'Search Criteria', current: true },
  { name: 'Saved Criteria', current: false },
  { name: 'Table Settings', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export type SearchCriteriaProps = {
  tabName: string;
  drawerToggle: { right: boolean };
  toggleDrawer: (ancdivhor: 'right', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const SearchCriteria: FC<SearchCriteriaProps> = ({ tabName = 'Set Search Criteria', drawerToggle, toggleDrawer }) => {
  const [ activeTab, setActiveTab ] = useState(tabs.find((tab) => tab.current).name);
  const [ tabState, setTabState ] = useState(tabs);

  const { filteringDispatch, isFilteringActive } = useFilteringContext();

  useEffect(() => {
    setActiveTab(tabName);
    const updatedTabs = tabState.map((tab) => ({
      ...tab,
      current: tab.name === tabName,
    }));
    setTabState(updatedTabs);
  }, [ tabName ]);

  const {
    totalPropertiesCount,
    criteriaMatchedPropertiesCount,
  } = usePropertyViewerContext();
  const { savedSearchCriteria, errorSavedSearchCriteria, isLoadingSavedSearchCriteria } = useGetSavedSearchCriteria();

  const handleTabClick = (tabName) => {
    const updatedTabs = tabState.map((tab) => ({
      ...tab,
      current: tab.name === tabName,
    }));
    setTabState(updatedTabs);
    setActiveTab(tabName);
  };

  // Clear search criteria from sliders and url params
  const handleClearSearchCriteria = () => {
    const newURL = window.location.pathname;
    window.history.pushState({}, '', newURL);
    // Reset all the dual input elements
    filteringDispatch({ type: 'RESET_FILTERS' });
  };

  const SearchCriteriaHeader = (
    <div id="search-criteria-header" className="bg-white p-1 align-center text-sm">
      <div className='float-left ml-300'>
        <Button onClick={toggleDrawer('right', false)}>
          <ChevronRightIcon className="h-10 w-10 flex-none text-blue-600" aria-hidden="true" />
        </Button>
      </div>
      <div className='flex justify-around items-center h-full py-2 text-gray-400'>
        <div>Total Properties in List: {totalPropertiesCount}</div>
        <div>Filtered Properties Count: {criteriaMatchedPropertiesCount}</div>
      </div>
    </div>
  );

  return (
    <div id="search-criteria-container">
      <React.Fragment key='right'>
        <Drawer
          id="search-criteria-drawer"
          variant="persistent"
          anchor='right'
          open={drawerToggle['right']}
          onClose={toggleDrawer('right', false)}
        >
          <div id="search-criteria-content" className="flex flex-col overflow-y-scroll flex-1 max-w-[500px]">
            {SearchCriteriaHeader}
            <div id="search-criteria-tabs" className="block">
              <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                {tabState.map((tab, tabIdx) => (
                  <a
                    key={tab.name}
                    onClick={() => handleTabClick(tab.name)}
                    className={classNames(
                      tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabState.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.current ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>

            {/* These are our slect dropdown input values */}
            {activeTab === tabState.at(0).name &&
              <div id="search-criteria-filter-display" className="overflow-y-scroll border-2 border-gray-100">
                <FilterDisplay />
              </div>
            }

            {/* These are the Saved Search Criteria */}
            {activeTab === tabState.at(1).name &&
              <div id="search-criteria-saved-criteria-container" className="overflow-y-scroll border-2 border-gray-100">
                <div className="grid grid-cols-2 px-5 py-5 gap-x-2 gap-y-5 overflow-y-scroll">
                  {savedSearchCriteria?.map((criteria, index) => (
                    <SavedSearchCard key={`saved-search-card-${index}`} criteria={criteria} index={index} />
                  ))}
                </div>
              </div>
            }

            {/* These are the Table Settings */}
            {activeTab === tabState.at(2).name &&
              <div id="search-criteria-table-settings-container" className="overflow-y-scroll border-2 border-gray-100 ">
                <div className="grid grid-cols-2 px-5 py-5 gap-x-2 gap-y-5 overflow-y-scroll max-w-[500px]">
                  <SizeSettings />
                  <ShouldShowGridLines />
                  <ShouldShowStripedRows />
                  <PaginationPerPage />
                  <RowSelectionToggle />
                  {/* ColumnsToggle takes up the whole row */}
                  <div className="col-span-2">
                    <ColumnsToggle />
                  </div>
                </div>
              </div>
            }

            {/* Calls to action: Save Search Criteria & Clear Search Criteria */}
            <div id="search-criteria-calls-to-action-footer" className="bg-gray-50 flex justify-evenly">
              {isFilteringActive ? (
                <>
                  <SaveSearchCriteria />
                  <button
                    key="customize_view"
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    onClick={handleClearSearchCriteria}
                  >
                    <XMarkIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    Clear Search Criteria
                  </button>
                </>
              )
                : (<div className="border-x border-gray-900/5">No Filters Set</div>)
              }
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default SearchCriteria;
