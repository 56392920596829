import { api } from '../configs/axiosConfigs';

export type GetPropertyMapMarkersProps = {
  ransackObj: any;
  numResults: number;
}

// Shared abort controller for cancelling in-flight requests
let abortController: AbortController | null = null;

export const getPropertyMapMarkers = async({ ransackObj, numResults }: GetPropertyMapMarkersProps) => {
  // Cancel any pending requests
  if (abortController) {
    abortController.abort();
  }

  // Create new controller for this request
  abortController = new AbortController();

  try {
    // Build query params
    const params = new URLSearchParams();
    params.append('num_results', numResults.toString());

    // Make request
    const response = await api.post(
      `/map_markers_with_filtering.json?${params.toString()}`,
      ransackObj,
      { signal: abortController.signal }
    );

    return response;
  } finally {
    // Clean up controller
    abortController = null;
  }
};
