import React from 'react';

import { capitalizeWordsWithUnderscore } from '../../../../utility/utility';
import LoadSearchCriteria from '../../LoadSearchCriteria';
import DeleteSearchCriteria from '../../DeleteSearchCriteria';

const keysToSkip = [ 'title', 'id', 'user_id', 'created_at', 'updated_at', 'sort' ];

const SavedSearchCard = ({ criteria, index }: { criteria: any, index: number }) => {

  function getSearchCriteriaList(criteria) {
    const dataArray = [];

    for (const [ key, value ] of Object.entries(criteria)) {
      if (value === null || value === undefined || value === '' ||
            (Array.isArray(value) && value.length === 0)) {
        continue;
      }
      if (keysToSkip.includes(key)) {
        continue;
      }
      else {
        dataArray.push(`${capitalizeWordsWithUnderscore(key)}: ${value}`);
      }
    }
    return dataArray.map((c) => (<p className="text-gray-600" key={c}>{c}</p>));
  }

  return (
    <div
      id={`search-criteria-saved-criteria-${criteria.id}`}
      key={criteria.id}
      className="rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md border-2 max-w-[224px]"
    >
      <div className="mt-6 block font-semibold text-gray-900">
        {`Criteria #${index + 1}`}: {criteria.title}
      </div>
      <div className="overflow-y-scroll h-40 order-2 border-gray-100">
        {getSearchCriteriaList(criteria)}
      </div>
      <div className="flex flex-col">
        <LoadSearchCriteria
          criteriaName={criteria.title}
          criteria={criteria}
        />
        <DeleteSearchCriteria
          criteriaTitle={criteria.title}
          criteriaId={criteria.id}
        />
      </div>
    </div>
  );
};

export default SavedSearchCard;
