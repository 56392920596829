import React, { useState, useEffect, type FC, useRef } from 'react';
import useGetPropertyDetails from '../../../hooks/api/Property/useGetPropertyDetails';
import { useCreateAvmResults } from '../../../hooks/api/AvmResults/useCreateAvmResults';
import PropertyQuickViewModalFooter from './PropertyQuickViewModalFooter';
import { getPropertyImages } from '../../../utility/utility_functions';
import useGetPropertyAvmResults from '../../../hooks/api/AvmResults/useGetPropertyAvmResults';
import PropertyQuickViewModalBody from './PropertyQuickViewModalBody';

export type PropertyQuickViewModalProps = {
  propertyId: string;
  handleClose?: () => void;
  setPopupLoading?: Function;
  isAvmComp?: boolean;
  // eslint-disable-next-line no-unused-vars
  setHeight?: (height: number) => void;
}

const PropertyQuickViewModal: FC<PropertyQuickViewModalProps> = ({ propertyId, handleClose, setPopupLoading = () => null, isAvmComp = false, setHeight = () => null }) => {
  const [ propertyImages, setPropertyImages ] = useState([]);
  const [ imageLoaded, setImageLoaded ] = useState(false);
  const { propertyDetails, isLoadingPropertyDetails } = useGetPropertyDetails(propertyId);
  const { avmResults } = useGetPropertyAvmResults(propertyId);
  const [ avmResult, setAvmResult ] = useState(null);
  const popupRef = useRef(null);
  // Creating Avm Result when you open the popup for now
  const createAvmResults = useCreateAvmResults();

  useEffect(() => {
    if (propertyDetails) {
      setPropertyImages(getPropertyImages(propertyDetails));
      createAvmResults.mutateAsync(propertyDetails.id);
    }
  }, [ propertyDetails ]);

  useEffect(() => {
    setPopupLoading(isLoadingPropertyDetails);
  }, [ isLoadingPropertyDetails ]);

  useEffect(() => {
    if (avmResults && propertyDetails) {
      let propertyAvmResult = avmResults?.results.filter((result) => result?.id === propertyDetails?.avm_result_id)[0];
      setAvmResult(propertyAvmResult);
    }
  }, [ avmResults, propertyDetails ]);

  useEffect(() => {
    if (popupRef.current) {
      setHeight(popupRef.current.offsetHeight);
    }
  }, [ imageLoaded ]);

  if (!propertyDetails && !isLoadingPropertyDetails) {
    return (<div className='p-8 bg-slate-300 z-40'>
      No property details
    </div>);
  }

  return (
    !isLoadingPropertyDetails && (
      <div
        id='property-detail-popup-container'
        className='cursor-pointer rounded-b-lg'
        ref={popupRef}
        onClick={() => window.open(`/properties/${propertyId}`, '_blank')}
      >
        <PropertyQuickViewModalBody
          propertyImages={propertyImages}
          propertyId={propertyDetails.id}
          handleClose={handleClose}
          setImageLoaded={setImageLoaded}
        />
        <PropertyQuickViewModalFooter
          property={propertyDetails}
          avmResult={avmResult}
          isAvmComp={isAvmComp}
        />
      </div>
    )
  );
};

export default PropertyQuickViewModal;
