import React, { type FC } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { LoadSearchCriteriaConfirmationModalProps } from '../../../../utility/types';
import { formatDate } from '../../../../utility/utility_functions';

const LoadSearchCriteriaConfirmationModal: FC<LoadSearchCriteriaConfirmationModalProps> = ({ isOpen, onClose, onConfirm, criteriaName, criteria }) => {
  function formatCriteria(criteria: any) {
    return (
      <div id="load-criteria-modal-criteria-container">
        {Object.entries(criteria).map(([ key, value ]: [string, any]) => {
          if (key === 'id' || key === 'user_id' || key === 'title' || !value) {
            return null;
          }
          if (key === 'created_at' || key === 'updated_at') {
            return <p key={key}>{key}: {formatDate(value)}</p>;
          }
          if (Array.isArray(value)) {
            if (value.length) {
              return <p key={key}>{key}: {value.join(', ')}</p>;
            }
            else {
              return null;
            }
          }
          if (key === 'favorite') {
            return value === true ? <p key={key}>{key}: value</p> : null;
          }
          if (value) {
            return <p key={key}>{key}: {value}</p>;
          }
          return null;
        })}
      </div>
    );
  }
  if (!isOpen) {
    return null;
  }
  return (
    <div id="load-criteria-modal-container" className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75">
      {/* Modal Box */}
      <div id="load-criteria-modal-box" className="relative w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-xl">
        {/* Header */}
        <div className="p-4 border-b border-gray-200">
          <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <h3 id="load-criteria-modal-title" className="text-base font-semibold text-gray-900">
            Confirm to load search criteria: {criteriaName}
          </h3>
        </div>

        {/* Scrollable Content */}
        <div id="load-criteria-modal-criteria-container" className="p-4 max-h-60 overflow-y-scroll">
          {formatCriteria(criteria)}
        </div>

        {/* Footer */}
        <div id="load-criteria-modal-footer-container" className="flex justify-evenly gap-3 p-4 border-t border-gray-200">
          <button
            type="button"
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            onClick={onConfirm}
          >
            Load Search Criteria
          </button>
        </div>
      </div>
    </div>
  );

};
export default LoadSearchCriteriaConfirmationModal;
