import React, { useEffect, useMemo, useState } from 'react';
import { usePropertyViewerContext } from '../../contexts/PropertyViewer';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CheckIcon_inactive, FavoriteIcon_inactive, IconHouse, Loader, SortAscending, SortDescending, Unsorted } from '../icons/OurIcons';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeflex/primeflex.css';                                   // css utility
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import { useAppSettingsContext } from '../../contexts/AppSettings';
import useGetProperties from '../../hooks/api/Property/useGetProperties';
import { useFilteringContext } from '../../contexts/Filtering';
import '../../../../app/assets/stylesheets/property-list-styles.css';
import PropertyDetailsButton from './buttons/PropertyDetailsButton';
import WantedButton from './buttons/WantedButton';
import BulkActionButtons from './buttons/BulkActionButtons';
import baseURL from '../../data/environmentVariables';
import { Dialog } from 'primereact/dialog';
import PropertyQuickViewModal from '../common/modals/PropertyQuickViewModal';
import Header from './Header';
import FavoriteToggle from '../common/FavoriteToggle';
import { formatPropertiesForTable } from '../../utility/utility';
import { AppDispatch, RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { toggleBulkLspjs } from '../../redux/state/lspjSlice';
import { useDispatch } from 'react-redux';
import { TableColumn, reorderColumns } from '../../redux/state/columnsSlice';

export type PropertiesListProps = {
  listSortId: string;
}

const List: React.FC<PropertiesListProps> = ({ listSortId }) => {
  const [ isPropertySummaryViewVisible, setIsPropertySummaryViewVisible ] = useState(false);
  const [ clickedPropertySummaryId, setClickedPropertySummaryId ] = useState(null);
  const [ propertiesForPrimeReactTable, setPropertiesForPrimeReactTable ] = useState([ {} ]);
  const [ selectedRows, setSelectedRows ] = useState([]);
  const [ selectAll, setSelectAll ] = useState(false);
  const [ frozenRow, setFrozenRow ] = useState([]);
  const { columns, originalColumns, isPropertyIdColumnVisible } = useSelector((state: RootState) => state.columns);
  const dispatch = useDispatch<AppDispatch>();

  // Contexts
  const { gridSize,
    datatableGridSize,
    shouldShowGridLines,
    shouldShowStripedRows,
    paginationPerPageCount,
    isRowSelectionEnabled,
    isFirstFewColumnsLocked,
  } = useAppSettingsContext();

  const { activeListSortId,
    setActiveListSortId,
    setTotalPropertiesCount,
    setPropertyDecisionCounts,
    setCriteriaMatchedPropertiesCount,
    setIsLassoSet
  } = usePropertyViewerContext();

  const { ransackObj, handleSort, sorting, filteringDispatch } = useFilteringContext();

  const [ lazyState, setLazyState ] = useState({
    rows: paginationPerPageCount,
    page: 0,
    multiSortMeta: null,
    first: 0,
  });

  const {
    propertiesData,
    propertyCounts,
    totalPropertyRecordsIds,
    totalPropertyRecordsFiltered,
    loadingProperties,
    errorProperties
  } = useGetProperties(listSortId, ransackObj, paginationPerPageCount, lazyState.page + 1, lazyState.multiSortMeta);

  const paddingClass = `custom-padding-${gridSize}`;
  const showBulkActionButtons = selectedRows?.length > 1 && listSortId;

  useEffect(() => {
    if (!listSortId) {
      setActiveListSortId('properties');
      return;
    }

    // if we arrived here from a link, or for some reason the activeListSortId is not set, set it here.
    if (!activeListSortId) {
      setActiveListSortId(listSortId);
    }
  }, []);

  useEffect(() => {
    // If lasso exists, search for properties within the lasso
    if (ransackObj?.gisFilter?.lasso) {
      filteringDispatch({
        type: 'SET_GIS_FILTER',
        gisFilter: {
          lasso: ransackObj.gisFilter.lasso,
          areaToBeSearched: ransackObj.gisFilter.lasso
        }
      });
    }
  }, [ ransackObj?.gisFilter?.lasso ]);

  // Format the properties for the table
  useEffect(() => {
    if (propertiesData) {
      // Format new properties for primereact table
      const newObject = formatPropertiesForTable(propertiesData);

      // I think these should maybe be in the custom hook
      setPropertiesForPrimeReactTable(newObject);
      setTotalPropertiesCount(propertyCounts.total_property_records);
      setPropertyDecisionCounts({
        wantedPropertiesCount: propertyCounts.total_wanted_property_joins,
        unwantedPropertiesCount: propertyCounts.total_unwanted_property_joins,
        decidedPropertiesCount: propertyCounts.total_sorted_property,
        undecidedPropertiesCount: propertyCounts.total_unsorted_property,
      });
      setCriteriaMatchedPropertiesCount(propertyCounts.total_property_records_filtered);
      setIsLassoSet(!!ransackObj.gisFilter?.areaToBeSearched);
    }
  }, [ propertiesData ]);

  useEffect(() => {
    if (errorProperties) {
      console.error('There was an error with the properties request: ', errorProperties);
      setPropertiesForPrimeReactTable([ {} ]);
    }
  }, [ errorProperties ]);

  useEffect(() => {
    setLazyState(prev => {
      prev.rows = paginationPerPageCount;
      return prev;
    });
  }, [ paginationPerPageCount ]);

  // Function to toggle the visibility of MyComponent
  const openPropertyQuickViewModal = (rowData) => {
    if (!rowData || rowData.id !== frozenRow[0]?.id) {
      setFrozenRow(rowData ? [ rowData ] : []);
      setIsPropertySummaryViewVisible(!!rowData);
    } else {
      setFrozenRow([]);
      setIsPropertySummaryViewVisible(false);
    }

    setClickedPropertySummaryId(rowData?.id);
  };

  const closePropertyQuickViewModal = () => {
    setFrozenRow([]);
    setIsPropertySummaryViewVisible(false);
    setClickedPropertySummaryId(null);
  };

  // Handlers
  const onPage = (event) => {
    setLazyState(event);
  };

  const onSort = (event: { multiSortMeta: Array<{ field: string; order: 0 | 1 | -1 }> }) => {
    setLazyState(prevState => ({ ...prevState, multiSortMeta: event.multiSortMeta }));
  };

  const onFilter = (event) => {
    setLazyState(event);
  };

  const onSelectionChange = (event) => {
    const value = event.value;
    setSelectedRows(value);
    setSelectAll(value.length === totalPropertyRecordsFiltered);
  };

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;
    setSelectAll(selectAll);
    // If we have un/selected all rows, we want to clear the property ids array
    if (selectAll) {
      setSelectedRows(totalPropertyRecordsIds?.map(v => ({ id: v })));
    }
    else {
      setSelectedRows([]);
    }
  };

  const onRowClick = (e) => {
    if (e?.data?.id) {
      const pointerEvent = e?.originalEvent?.nativeEvent;
      // Check if the command key (cmd) is pressed. If it is, open the property in a new tab
      if (pointerEvent?.metaKey) {
        window.open(`${baseURL}/properties/${e?.data?.id}`, '_blank');
      }
    }
  };

  const multiClickStyle = useMemo(() => {
    switch (gridSize) {
    case 'large':
      return { width: '3rem', paddingLeft: '16px' };
    case 'normal':
      return { width: '3rem', paddingLeft: '16px' };
    default:
      return { width: '3rem', paddingLeft: '16px' };
    }
  }, [ gridSize ]);

  function sortingIcon(field: string) {
    if (field === 'full_address') {
      return null;
    }
    if (!sorting || (sorting && sorting?.field !== field)) {
      return <Unsorted />;
    }
    if (sorting?.direction === 'asc') {
      return <SortAscending />;
    }
    if (sorting?.direction === 'desc') {
      return <SortDescending />;
    }
    return;
  }

  const onColumnReorder = (event) => {
    // PrimeReact's event gives us the original index (dragIndex) and the new index (dropIndex)
    const nonorderableColumns = event.columns.length - originalColumns.length;
    dispatch(reorderColumns({
      startIndex: event.dragIndex - nonorderableColumns,
      endIndex: event.dropIndex - nonorderableColumns
    }));
  };

  const header = () => {
    return <Header />;
  };

  const columnBody = (rowData, field, unit, unit_type) => {
    const fieldValue = rowData[field];
    if (fieldValue === null || fieldValue === undefined) {
      return <div>-</div>;
    }
    if (field === 'full_address') {
      return <div
        className="hover:cursor-default hover:underline"
        onClick={() => {
          window.open(`/properties/${rowData.property_id}`, '_blank');
        }}
      >{fieldValue}</div>;
    }
    if (unit_type === 'number') {
      return <div>{fieldValue.toLocaleString()}</div>; // Ensure data is a number
    }
    if (unit_type === 'dollars') {
      return <div>${fieldValue.toLocaleString()}</div>; // Ensure data is a dollar
    }
    if (unit_type === 'date') {
      return <div>{new Date(fieldValue).toLocaleDateString()}</div>; // Ensure data is a date
    }
    return <div>{fieldValue}</div>;
  };

  return (
    <>
      <Dialog
        showHeader={false}
        visible={isPropertySummaryViewVisible}
        position={'right'}
        onHide={closePropertyQuickViewModal}
        pt={{
          root: { className: 'w-auto mr-6 dark:border-slate-400' },
          content: { className: 'p-0 rounded-lg' }
        }}
        draggable={false}
        resizable={false}
        modal={false} // Set to false for non-blocking behavior
      >
        <PropertyQuickViewModal
          propertyId={clickedPropertySummaryId}
          handleClose={closePropertyQuickViewModal}
        />
      </Dialog>

      <div>
        {showBulkActionButtons && <BulkActionButtons
          selectedRows={selectedRows}
          listSortId={listSortId}
          handleMarkAllAsWanted={() => dispatch(toggleBulkLspjs({ listSortId, propertyIds: selectedRows.map(v => v.id), action: 'wanted' }))}
          handleMarkAllAsUnwanted={() => dispatch(toggleBulkLspjs({ listSortId, propertyIds: selectedRows.map(v => v.id), action: 'not_wanted' }))}
          handleMarkAllAsUndecided={() => dispatch(toggleBulkLspjs({ listSortId, propertyIds: selectedRows.map(v => v.id), action: 'delete' }))}
        />}

        <div id="properties-list" className="card text-center border-2 border-gray-300">
          <DataTable
            id="properties-list-data-table"
            pt={{ paginator: { pageButton: { className: 'rounded-full' } } }}
            dataKey="id"
            emptyMessage="No Properties found."
            lazy
            loading={loadingProperties}
            loadingIcon={<Loader width={100} height={100} />}
            onFilter={onFilter}
            onPage={onPage}
            onSelectAllChange={onSelectAllChange}
            onSelectionChange={onSelectionChange}
            paginator
            resizableColumns
            columnResizeMode="expand"
            scrollable
            scrollHeight="60vh"
            selectAll={selectAll}
            selection={selectedRows}
            selectionMode={isRowSelectionEnabled ? null : 'checkbox'}
            showGridlines={shouldShowGridLines}
            size={datatableGridSize}
            stripedRows={shouldShowStripedRows}
            value={propertiesForPrimeReactTable}
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalPropertyRecordsFiltered}
            className={paddingClass}
            header={header}
            onRowClick={onRowClick}
            frozenValue={frozenRow}
            onColReorder={onColumnReorder}
            reorderableColumns={true}
            removableSort
            onSort={onSort}
            sortMode="multiple"
            multiSortMeta={lazyState.multiSortMeta}
          >
            <Column selectionMode="multiple"
              className="p-10 text-center"
              frozen={isFirstFewColumnsLocked}
              headerStyle={multiClickStyle}
              bodyStyle={multiClickStyle}
            />
            {listSortId &&
              <Column
                field="wantedStatus"
                key="wantedStatus"
                className="text-center"
                frozen={isFirstFewColumnsLocked}
                header={<CheckIcon_inactive />}
                body={(rowData) => (
                  <WantedButton
                    key={`wanted-button-${rowData.id}`}
                    id={rowData.id}
                  />
                )}
              />
            }
            <Column
              field="propertyDetails"
              key="propertyDetails"
              header={() => <IconHouse />}
              bodyClassName={paddingClass}
              bodyStyle={{ textAlign: 'center' }}
              frozen={isFirstFewColumnsLocked}
              body={(rowData) => (
                <PropertyDetailsButton
                  key={rowData.id}
                  rowData={rowData}
                  onClick={openPropertyQuickViewModal}
                  isActive={isPropertySummaryViewVisible && rowData.id === clickedPropertySummaryId}
                />
              )}
            />
            <Column
              field="favoriteStatus"
              key="favoriteStatus"
              frozen={isFirstFewColumnsLocked}
              header={<FavoriteIcon_inactive />}
              className="text-center"
              body={(rowData) => (
                <FavoriteToggle
                  key={rowData?.id}
                  propertyId={rowData?.id}
                />
              )}
            />

            {columns?.map((col: TableColumn) => {
              if (!col) {
                return null;
              }
              if (col.field === 'property_id' && !isPropertyIdColumnVisible) {
                return null;
              }
              if (col.visible) {
                return (
                  <Column
                    key={`property-list-table-column-${col.field}`}
                    field={col.field}
                    header={
                      (<div onClick={(e) => handleSort({ field: col.field, direction: null })}>
                        {col.header}
                        {sortingIcon(col.field)}
                      </div>)}
                    body={(data) => columnBody(data, col.field, col.unit, col.unit_type)}
                    bodyStyle={{ textAlign: 'center' }}
                    frozen={col.field === 'full_address' && isFirstFewColumnsLocked}
                  />
                );
              }
            })}
          </DataTable>
        </div>
      </div>

      {errorProperties && (
        <div className="text-center text-red-500">Sorry about that! There was an error with the properties request. Please try again later.</div>
      )}
    </>
  );
};
export default List;
