import { Anchor, LngLatLike, PointLike } from 'mapbox-gl';
import { AddressType, MapBoundingBox } from './types';
import { MapRef } from 'react-map-gl';
import React from 'react';
import { IconHouseFill, Loader, MapIcon_heart } from '../components/icons/OurIcons';

export const POPUP_WIDTH = 320;
export const WIDE_MAP = POPUP_WIDTH * 2 + 10;
export const Z_INDEX_SELECTED_MARKER = 10;
export const Z_INDEX_MAP_POPUP = 11;

type createMarkerProps = {
  addressId: string;
  popupInfoId: string;
  listPrice: number;
  isRental: boolean;
  isMainProperty: boolean;
  popupLoading: boolean;
  isFavorite: boolean;
}

const calculateOrientation = (marker: DOMRect, boundingBox: MapBoundingBox): Anchor => {
  const mapCenterX = (boundingBox.left + boundingBox.right) / 2;
  const markerCenterX = (marker.left + marker.right) / 2;
  return mapCenterX > markerCenterX ? 'left' : 'right';
};

const calculateHeightOffset = (
  marker: DOMRect,
  boundingBox: MapBoundingBox,
  popupHeight: number
): number => {
  const PADDING = 5;
  const halfPopupHeight = popupHeight / 2;
  const markerMidpoint = (marker.top + marker.bottom) / 2;
  const heightWithPadding = halfPopupHeight + PADDING;

  const topOverflow = boundingBox.top + heightWithPadding - markerMidpoint;
  const bottomOverflow = boundingBox.bottom - markerMidpoint - heightWithPadding;

  if (bottomOverflow < 0) return bottomOverflow;
  if (topOverflow > 0) return topOverflow;

  return 0;
};

export const formatPriceForMarker = (price) => {
  if (!price) return '$----';
  if (price >= 100000000) return `$${Math.round(price / 1000000)}M`; // > 100M
  if (price >= 10000000) return `$${Math.round(price / 100000) / 10}M`; // > 10M
  if (price >= 1000000) return `$${Math.round(price / 10000) / 100}M`; // > 1M
  if (price >= 100000) return `$${Math.round(price / 1000)}k`; // > 100k
  if (price >= 1000) return `$${Math.round(price / 100) / 10}k`; // > 1k
  return `$${price}`;
};

export const getMapboxBoundingBox = (mapRef: MapRef) => {
  if (!mapRef) return null;

  const container = mapRef.getContainer().getBoundingClientRect();
  return {
    left: container.left,
    right: container.right,
    top: container.top,
    bottom: container.bottom,
    width: container.width
  };
};

export const getMapboxPopupOrientationAndOffset = (
  markerRef: mapboxgl.Marker,
  mapNode: MapRef,
  popupHeight: number,
): [Anchor, PointLike] => {
  if (!markerRef) return null;

  const mapBoundingBox = getMapboxBoundingBox(mapNode);
  const markerCoordinates = markerRef.getElement().getBoundingClientRect();
  const orientation = calculateOrientation(markerCoordinates, mapBoundingBox);
  const widthOffset = orientation === 'left' ? 20 : -20;
  const heightOffset = calculateHeightOffset(markerCoordinates, mapBoundingBox, popupHeight);

  return [ orientation, [ widthOffset, heightOffset ] ];
};

export const moveMapMarkerToLeftEdge = (
  mapNode: MapRef,
  address: AddressType,
  // eslint-disable-next-line no-unused-vars
  setPreviousCenter: (center: mapboxgl.LngLat) => void
) => {
  const prevCenter = mapNode.getCenter();
  setPreviousCenter(prevCenter);

  const { _ne, _sw } = mapNode.getBounds();
  const mapWidth = _ne.lng - _sw.lng;
  const offset = mapWidth * .45;
  const newCenter: LngLatLike = [ parseFloat(address.longitude) + offset, parseFloat(address.latitude) ];

  mapNode.setCenter(newCenter);
};

export const createMarker = (props: createMarkerProps) => {
  const shortenedPrice = formatPriceForMarker(props.listPrice);
  const isSelected = props.addressId === props.popupInfoId;

  const backgroundColor = props.isRental
    ? (isSelected ? 'bg-yellow-800' : 'bg-yellow-500')
    : (isSelected ? 'bg-green-800' : 'bg-green-500');
  const dotStyling = 'flex items-center justify-center rounded-full drop-shadow fill-white text-white cursor-pointer text-lg';
  const priceContainerStyling = `absolute px-1 py-0.5 rounded-2xl cursor-pointer ${isSelected ? 'z-10' : ''}`;
  const priceStyling = 'w-max bg-white px-1 leading-3 rounded-lg';

  const markerIcon = props.isMainProperty ? (
    <>
      <div className={`${dotStyling} w-6 h-6 bg-indigo-800`}>
        <IconHouseFill color="White" className='w-3/4 pb-px'/>
      </div>
      <div className={`${priceContainerStyling} mt-0.5 bg-indigo-800 ${shortenedPrice.length > 3 ? '-translate-x-1/4' : '-translate-x-1.5'}`}>
        <p className={priceStyling}>{shortenedPrice}</p>
      </div>
    </>
  ) : props.isFavorite ? (
    <div id={`favorite-marker-${props.addressId}`} className='cursor-pointer'>
      <MapIcon_heart />
      {props.popupLoading && isSelected && (
        <div className="absolute w-10 -bottom-2 -right-2">
          <Loader />
        </div>
      )}
      <div className={`${priceContainerStyling} bg-red-500 ${shortenedPrice.length > 3 ? '-translate-x-3' : '-translate-x-2.5'}`}>
        <p className={priceStyling}>{shortenedPrice}</p>
      </div>
    </div>
  ) : (
    <>
      <div className={`${dotStyling} w-4 h-4 z-10 ${backgroundColor}`}></div>
      {props.popupLoading && isSelected && (
        <div className="absolute w-10 -bottom-3 -right-3">
          <Loader />
        </div>
      )}
      <div className={`${priceContainerStyling} mt-0.5 ${backgroundColor} ${shortenedPrice.length > 3 ? '-translate-x-1/3' : '-translate-x-1/4'}`}>
        <p className={priceStyling}>{shortenedPrice}</p>
      </div>
    </>);

  return markerIcon;
};
