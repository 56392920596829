import React, { useCallback, useEffect, useState, type FC } from 'react';
import useGetPropertyComments from '../../hooks/api/Property/useGetPropertyComments';
import ImagesMapDisplay from './ImagesMapDisplay';
import { AvmCompForMap, PropertyDataProps } from '../../utility/types';

type ImagesMapContainerProps = {
  property: PropertyDataProps;
  avmCompsForMap?: AvmCompForMap[];
  galleriaRef: any;
  mlsPropertyImages: any[];
  propertyId: string;
  isLoading: boolean;
  error: string;
}

const ImagesMapContainer: FC<ImagesMapContainerProps> = ({ property, avmCompsForMap, galleriaRef, mlsPropertyImages, propertyId, isLoading, error }) => {
  const [ userImages, setUserImages ] = useState([]);
  const [ viewMap, setViewMap ] = useState(false);
  const [ viewMlsImages, setViewMlsImages ] = useState(true);
  const [ viewUserImages, setViewUserImages ] = useState(false);
  const [ viewNearbyListingsMap, setViewNearbyListingsMap ] = useState(false);

  const { commentData } = useGetPropertyComments(propertyId);

  useEffect(() => {
    if (commentData?.comment?.photos) {
      setUserImages(commentData?.comment?.photos);
    }
  }, [ commentData ]);

  const handleViewMlsImages = useCallback(() => {
    setViewMlsImages(true);
    setViewMap(false);
    setViewUserImages(false);
    setViewNearbyListingsMap(false);
  }, []);

  const handleViewMap = useCallback(() => {
    setViewMap(true);
    setViewMlsImages(false);
    setViewUserImages(false);
    setViewNearbyListingsMap(false);
  }, []);

  const handleViewNearbyListingsMap = useCallback(() => {
    setViewMap(false);
    setViewMlsImages(false);
    setViewUserImages(false);
    setViewNearbyListingsMap(true);
  }, []);

  const handleViewUserImages = useCallback(() => {
    setViewUserImages(true);
    setViewMap(false);
    setViewMlsImages(false);
    setViewNearbyListingsMap(false);
  }, []);

  return (
    <div className='rounded-xl shadow-2xl'>
      <div className='text-left px-3 pt-4'>
        <span
          className='whitespace-nowrap text-extrabold text-lg text-indigo-500 text-left hover:cursor-pointer'
          onClick={handleViewMlsImages}
        >
        MLS Images
        </span>
        <span> | </span>
        <span
          className='whitespace-nowrap text-extrabold text-lg text-indigo-500 text-left hover:cursor-pointer'
          onClick={handleViewMap}
        >
          Map
        </span>
        <span> | </span>
        <span
          className='whitespace-nowrap text-extrabold text-lg text-indigo-500 text-left hover:cursor-pointer'
          onClick={handleViewNearbyListingsMap}
        >
          Nearby Listings Map
        </span>
        <span> | </span>
        <span
          className='whitespace-nowrap text-extrabold text-lg text-indigo-500 text-left hover:cursor-pointer'
          onClick={handleViewUserImages}
        >
        My Images
        </span>
        <hr className='mt-3' />
      </div>
      <div className='w-full'>
        <ImagesMapDisplay
          property={property}
          avmCompsForMap={avmCompsForMap}
          viewMap={viewMap}
          viewNearbyListingsMap={viewNearbyListingsMap}
          viewMlsImages={viewMlsImages}
          viewUserImages={viewUserImages}
          galleriaRef={galleriaRef}
          mlsPropertyImages={mlsPropertyImages}
          userImages={userImages}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </div>
  );
};

export default ImagesMapContainer;
