import React, { FC, useEffect, useState } from 'react';
import CreateListSortModal from './CreateListSortModal';
import DeleteListSortModal from './DeleteListSortModal';
import EditListSortModal from './EditListSortModal';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useGetListSorts } from '../../hooks/api/ListSort/useGetListSorts';
import { CheckIcon_active, Loader, CircleMinusIcon, XCircleIcon_active } from '../icons/OurIcons';
import useSessionStorage from '../../hooks/useSessionStorage';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnSortEvent } from 'primereact/column';
import { formatDate } from '../../utility/utility_functions';
import ShareListSortModal from './ShareListSortModal';

// Create a client
const queryClient = new QueryClient();

export type ListViewerProps = {
  rootUrl: string;
  mlsDataSources: string[];
}

export interface ListSortTableData {
  id: string;
  title: string;
  dateCreated: string;
  listUsed: string;
  totalProperties: number;
  unsortedCount: number;
  wantedCount: number;
  notWantedCount: number;
  decisionReadyCount: number;
}

const ListSortsViewer: FC<ListViewerProps> = (props) => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <ListSortsViewerChild {...props} />
    </QueryClientProvider>
  );
};

const getListUsed = (list_upload_name: string, data_source: string) => {
  if (list_upload_name) {
    return list_upload_name;
  } else if (data_source === 'NONE') {
    return 'None Provided';
  } else {
    return 'MLS';
  }
};

const ListSortsViewerChild: FC<ListViewerProps> = ({ rootUrl, mlsDataSources }) => {
  const [ selectedListSortData, setSelectedListSortData ] = useState({ id: 'id', name: 'name', notes: '', uploadName: '' });
  const [ isCreateNewListSortModalOpen, setIsCreateNewListSortModalOpen ] = useState(false);
  const [ isEditListSortModalOpen, setIsEditListSortModalOpen ] = useState(false);
  const [ isShareListSortModalOpen, setIsShareListSortModalOpen ] = useState(false);
  const [ isConfirmDeleteListSortModalOpen, setIsConfirmDeleteListSortModalOpen ] = useState(false);
  const { storedValue: activeListSortId, setValue: setActiveListSortId } = useSessionStorage('activeListSort', '');

  const { data: listSorts, isLoading, refetch: refetchGetListSorts, ...rest } = useGetListSorts();
  const [ tableData, setTableData ] = useState<ListSortTableData[]>([]);

  useEffect(() => {
    if (listSorts) {
      setTableData(
        listSorts?.map((ls) => {
          const wantedCount = ls.list_sort_property_joins?.filter((p) => p.wanted === true).length;
          const notWantedCount = ls.list_sort_property_joins?.filter((p) => p.wanted === false).length;
          const undecidedCount = (ls.total_properties - wantedCount - notWantedCount);
          return ({
            id: ls.id,
            title: ls.title,
            notes: ls.notes,
            dateCreated: formatDate(ls.created_at),
            listUsed: getListUsed(ls.list_upload_name, ls.data_source),
            totalProperties: ls.total_properties,
            unsortedCount: undecidedCount,
            wantedCount: wantedCount,
            notWantedCount: notWantedCount,
            decisionReadyCount: ls.fdm_lspjs_count,
          });
        })
      );
    }
  }, [ listSorts ]);

  const activeListSortRow = tableData?.find((ls) => ls.id === activeListSortId);

  /* ----- Button Click to Open Modal Functionality ----- */
  const handleNewListButtonClick = () => {
    setIsCreateNewListSortModalOpen(true);
  };

  const onShareClick = (listSortId, listSortName) => {
    setIsShareListSortModalOpen(true);
    setSelectedListSortData({ id: listSortId, name: listSortName, notes: '', uploadName: '' });
  };

  const onEditClick = (idParam, listSortToEditName, listSortToEditNotes, listSortToEditUploadName) => {
    setIsEditListSortModalOpen(true);
    setSelectedListSortData({ id: idParam, name: listSortToEditName, notes: listSortToEditNotes, uploadName: listSortToEditUploadName });
  };

  const onDeleteClick = (idParam, listSortToDeleteName) => {
    setIsConfirmDeleteListSortModalOpen(true);
    setSelectedListSortData({ id: idParam, name: listSortToDeleteName, notes: '', uploadName: '' });
  };

  const onRowClick = (e) => {
    if (e?.data?.id) {
      setActiveListSortId(e?.data?.id);
      window.location.href = `${rootUrl}list-sorts/${e?.data?.id}`;
    }
  };

  const customSortDateCreated = (event: ColumnSortEvent) => {
    const { data, field, order } = event;

    data.sort((a, b) => {
      // Parse the date strings into Date objects
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);

      // Compare the dates based on the order
      if (dateA < dateB) return -1 * order;
      if (dateA > dateB) return 1 * order;
      return 0;
    });
    return data;
  };

  /* ------- Close modal functionality ------- */
  const handleCreateListSortModalClose = () => {
    setIsCreateNewListSortModalOpen(false);
  };

  const handleDeleteListSortModalClose = () => {
    setIsConfirmDeleteListSortModalOpen(false);
    setSelectedListSortData({ id: '', name: '', notes: '', uploadName: '' });
  };

  const handleEditListSortModalClose = () => {
    setSelectedListSortData({ id: '', name: '', notes: '', uploadName: '' });
    setIsEditListSortModalOpen(false);
  };
  /* ------------------------------------ */

  const loader = () => {
    return (
      <div className="top-full flex justify-center items-center w-full h-full">
        <Loader width={50} height={50} />
      </div>
    );
  };

  const dt = () => {
    return (
      <DataTable
        className="list-sort-datatable"
        value={tableData}
        tableStyle={{ minWidth: '50rem' }}
        sortField="dateCreated"
        sortOrder={-1}
        selectionMode="single"
        selection={activeListSortRow}
        style={{ alignContent: 'center' }}
        onRowClick={onRowClick}
        loading={isLoading}
        loadingIcon={loader()}
        pt={{
          table: { className: 'list-sort-table' },
          headerRow: { className: 'list-sort-header-row' },
        }}
      >
        <Column field="title" header="Name" sortable style={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} bodyStyle={{ fontSize: '18px !important', fontWeight: 'bold' }}></Column>
        <Column field="dateCreated" header="Date Created" sortable sortFunction={customSortDateCreated} style={{ textAlign: 'center', maxWidth: '100px' }} ></Column>
        <Column field="listUsed" header="List Used" sortable style={{ textAlign: 'center' }}></Column>
        <Column field="totalProperties" header="Total Properties" sortable style={{ textAlign: 'center' }} headerStyle={{ whiteSpace: 'pre-line', maxWidth: '100px' }}></Column>
        <Column field="unsortedCount" header={<CircleMinusIcon />} sortable style={{ textAlign: 'center' }} headerStyle={{ whiteSpace: 'pre-line', maxWidth: '100px' }}></Column>
        <Column field="wantedCount" header={<CheckIcon_active />} sortable style={{ textAlign: 'center' }} headerStyle={{ whiteSpace: 'pre-line', maxWidth: '100px' }}></Column>
        <Column field="notWantedCount" header={<XCircleIcon_active />} sortable style={{ textAlign: 'center' }} headerStyle={{ whiteSpace: 'pre-line', maxWidth: '100px' }}></Column>
        <Column field="decisionReadyCount" header="Decision Ready" sortable style={{ textAlign: 'center' }} headerStyle={{ whiteSpace: 'pre-line', maxWidth: '100px' }}></Column>
        <Column
          field="actions"
          header="Actions"
          style={{ textAlign: 'center' }}
          body={(rowData) => (
            <div className="whitespace-nowrap px-3 py-5 text-sm text-center text-gray-500 hover:cursor-auto">
              <button onClick={() => onShareClick(rowData.id, rowData.title)} className="text-indigo-600 hover:text-indigo-200 px-1">Share</button>
              <button onClick={() => onEditClick(rowData.id, rowData.title, rowData.notes, rowData.list_upload_name)} className="text-indigo-600 hover:text-indigo-200 px-1">Edit</button>
              <button onClick={() => onDeleteClick(rowData.id, rowData.title)} className="text-indigo-600 hover:text-indigo-200 px-1">Delete</button>
            </div>
          )}
        />
      </DataTable>
    );
  };

  return (
    <>
      {isCreateNewListSortModalOpen && <CreateListSortModal
        isOpen={isCreateNewListSortModalOpen}
        onClose={handleCreateListSortModalClose}
        mlsDataSources={mlsDataSources}
      />}
      {isConfirmDeleteListSortModalOpen && <DeleteListSortModal
        isOpen={isConfirmDeleteListSortModalOpen}
        onClose={handleDeleteListSortModalClose}
        listSortName={selectedListSortData.name}
        listSortId={selectedListSortData.id}
      />}
      {isShareListSortModalOpen && <ShareListSortModal
        isOpen={isShareListSortModalOpen}
        onClose={() => {
          setIsShareListSortModalOpen(false);
        }}
        listSortName={selectedListSortData.name}
        listSortId={selectedListSortData.id}
      />}
      {isEditListSortModalOpen && <EditListSortModal
        isOpen={isEditListSortModalOpen}
        onClose={handleEditListSortModalClose}
        listSortEditableProperties={{ name: selectedListSortData.name, notes: selectedListSortData.notes, list_upload_name: selectedListSortData.uploadName, list_upload_id: selectedListSortData.id }}
      />}
      <div className="px-0 lg:px-0 xs:px-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">List Sorts</h1>
            <p className="mt-2 text-sm text-gray-700">
              Filter properties using the complete MLS or utilize your own property list that you've uploaded
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 flex sm:flex-none">
            <a
              href={`${rootUrl}list-uploads`}
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Manage Lists
            </a>
            <button
              type="button"
              className="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleNewListButtonClick}
            >
              Start a List Sort
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root border border-gray-300">
          {dt()}
        </div>
      </div>
    </>
  );
};

export default ListSortsViewer;
