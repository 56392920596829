import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createAvmResults } from '../../../api/AvmResults/createAvmResult';

export function useCreateAvmResults() {
  const queryClient = useQueryClient();

  const createAvmResultsMutation = useMutation({
    mutationFn: async (propertyId: string) => {
      try {
        return await createAvmResults(propertyId);
      } catch (err) {
        console.error('There was an error in useCreateAvmResults', err);
      }
    },
    onSuccess: (propertyId) => {
      queryClient.invalidateQueries({ queryKey: [ 'getPropertyAvmResults', propertyId ] });
    },
  });

  return createAvmResultsMutation;
}
