import React, { useState } from 'react';
import LoadSearchCriteriaConfirmationModal from './Components/modals/LoadSearchCriteriaConfirmationModal';
import { useFilteringContext } from '../../contexts/Filtering';

// Show the modal for Load Serach Criteria and set the potential criteria to load

const LoadSearchCriteria = ({ criteriaName, criteria }) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const { filteringDispatch } = useFilteringContext();

  const onLoadModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // User has selected to load a Search Criteria
  const handleLoadSearchCriteria = () => {
    setIsModalOpen(false);
    filteringDispatch({ type: 'LOAD_CRITERIA_FROM_SAVED', criteria: criteria });
  };

  return (
    <>
      <button
        key={`load_criteria_button_${criteriaName}`}
        className="h-fit mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
        onClick={onLoadModalOpen}
      >
        Load Search Criteria
      </button>
      {isModalOpen && (
        <LoadSearchCriteriaConfirmationModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleLoadSearchCriteria}
          criteriaName={criteriaName}
          criteria={criteria}
        />
      )}
    </>
  );
};

export default LoadSearchCriteria;
