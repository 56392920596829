import { AddressType, FormattedPropertyType, PropertyDataProps } from './types';

export function capitalizeWordsWithUnderscore(str) {
  const words = str.split('_');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}

export const capitalizeString = (capitalizeString: string) => {
  return capitalizeString.charAt(0).toUpperCase() + capitalizeString.slice(1);
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const formatPropertiesForTable = (properties: PropertyDataProps[]): FormattedPropertyType[] => {
  return Object.values(properties).map((property, index) => {
    if (!property) {
      return null;
    }
    let newObj = {
      index: index,
      id: property.id,
      property_id: property.id,
      favoriteStatus: property.property_favorite_flags?.length > 0,
      full_address: formatAddressFromPieces(property.address),
      avm_result: property.most_recent_avm_result?.estimated_value,
      potential_equity: property.most_recent_avm_result?.potential_equity,
      avm_price_per_sqft: property.most_recent_avm_result?.estimated_dollar_per_sqft,
      city: property.address?.city,
      zipcode: property.address?.zipcode,
      state_abbreviation: property.address?.state,
      ...property
    };
    delete newObj.most_recent_avm_result;
    delete newObj.address; // This line removes the 'address' key from newObj (we are getting it specifically above)
    return newObj;
  });
};

export const formatAddressFromPieces = (address: AddressType) => {
  if (!address) {
    return 'No Valid Address Provided';
  }
  const full_address = address?.full_address || address?.search_string || `${address.street } ${address.state} ${address.zipcode}` || 'No Valid Address Provided';
  // Remove the ', USA' from the address
  let modifiedAddress = full_address.replace(', USA', '');
  return modifiedAddress;
};
